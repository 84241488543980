import React, { useState, useEffect } from "react";
import { Button, Container, Breadcrumb, Row, Col, Table, Placeholder, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import CreateTag from "../components/modals/CreateTag";
import CreateTagUserLink from "../components/modals/CreateTagUserLink";
import CreateTagUsers from "../components/modals/CreateTagUsers";
import { INDEX_ROUTE } from "../utils/consts";
import { NavLink, useNavigate, useLocation } from "react-router-dom"
import WidthModal from "../components/modals/WidthModal"
import { observer } from "mobx-react-lite";
import Nav from 'react-bootstrap/Nav';
import ShowToast from "../components/modals/ShowToast"
import Modal from 'react-bootstrap/Modal';
import { fetchUp, upCreate, fetchUpAll, upEdit, fetchLists, upCreateV, fetchUpAllV, upEdit2, fetchUpAll2, getChangess } from "../http/salesAPI";
import { GetListOfBanks} from "../http/bankAPI";
import SelectReact from "../components/SelectReact";

import { confirm } from "../components/confirm/Confirmation";

import { CSVLink } from 'react-csv'

const priceSet = function (data, type = false) {
  /*
   * В переменной price приводим получаемую переменную в нужный вид:
   * 1. принудительно приводим тип в число с плавающей точкой,
   *    учли результат 'NAN' то по умолчанию 0
   * 2. фиксируем, что после точки только в сотых долях
   */
  var price = Number.prototype.toFixed.call(parseFloat(data) || 0, 2),
    //заменяем точку на запятую
    price_sep = price.replace(/(\D)/g, ","),
    //добавляем пробел как разделитель в целых
    price_sep = price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

  if (type)
    return price_sep;
  else
    return <b>{price_sep}</b>;
};

const sumSalaries = function (salaries) {

  let sum = 0;
  let sum2 = 0;
  let sum3 = 0;
  let sum4 = 0;
  let count = 0;
  for (let salary of Object.values(salaries)) {
    count++;
    sum += salary.price;
    sum2 += salary.price_sms;
    sum3 += ((salary.price - salary.price_sms) - ((salary.price - salary.price_sms) * salary.comission));
    sum4 += salary.price_sms+salary.price_pos;

  }

  return "Записей: " + count + "; Сумма: " + priceSet(sum, true) + "; Сумма СМС: " + priceSet(sum2, true) + "; Сумма без СМС: " + priceSet((sum - sum2), true) + "; Сумма без комисси: " + priceSet((sum3), true)+ "; Сумма СМС с POS: " + priceSet((sum4), true)
}

const regions2 = {
  1745923: 'ф.Пенза',
  3999631: 'КЦ Пермь',
  1745926: 'ф.Ульяновск',
  721543: 'ф.Пермь',
  1843345: 'ф.Магнитогорск',
  1843756: 'ф.Ярославль',
  3145828: 'ф. Уфа',
  1843759: 'ф.Самара',
  3999637: 'КЦ Ульяновск',
  7346290: 'ф.Пермь Якимова',
  7801106: 'ф.Пермь Исаева',
  7955966: 'КЦ Пермь',
  8017242: 'ф.Самара'
};

const getUnique = (arr) => {
  return arr.filter((el, ind) => ind === arr.indexOf(el));
};

const useSortableData = (items, config = { key: 'id', direction: 'ascending' }) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  const [cityfarr, setCityfArr] = useState([])
  const [parthnersfarr, setParthnersfArr] = useState([])
  const [statusfarr, setStatusfArr] = useState([])
  const [filterfarr, setFilterfArr] = useState({})

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }


    if (cityfarr.length !== 0)
      sortableItems = sortableItems.filter(function (f) {
        return cityfarr.includes(f.city_id)
      });
    if (parthnersfarr.length !== 0)
      sortableItems = sortableItems.filter(function (f) {
        return parthnersfarr.includes(f.parthner_id)
      });
    if (Object.keys(filterfarr).length !== 0)
      sortableItems = sortableItems.filter(function (f) {
        return !filterfarr[f.parthner_id.split("_")[1]]
      });
    if (statusfarr.length !== 0)
      sortableItems = sortableItems.filter(function (f) {
        return statusfarr.includes(f.status)
      });

    return sortableItems;
  }, [items, sortConfig, cityfarr, parthnersfarr, statusfarr, filterfarr]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  const cityFilter = (value) => {
    if (!value) {
      setCityfArr([])
      return
    }
    if (!cityfarr.includes(value))
      cityfarr.push(value)
    else
      delete (cityfarr[cityfarr.indexOf(value)])
    setCityfArr(JSON.parse(JSON.stringify(cityfarr.filter(element => element != null))))
  }
  const parthnersFilter = (value) => {
    if (!value) {
      setParthnersfArr([])
      return
    }
    if (!parthnersfarr.includes(value))
      parthnersfarr.push(value)
    else
      delete (parthnersfarr[parthnersfarr.indexOf(value)])
    setParthnersfArr(JSON.parse(JSON.stringify(parthnersfarr.filter(element => element != null))))
  }
  const statusFilter = (value) => {
    if (!value) {
      setStatusfArr([])
      return
    }
    if (!statusfarr.includes(value))
      statusfarr.push(value)
    else
      delete (statusfarr[statusfarr.indexOf(value)])
    setStatusfArr(JSON.parse(JSON.stringify(statusfarr.filter(element => element != null))))
  }

  const filterFilter = (value) => {
    setFilterfArr(JSON.parse(JSON.stringify(value)))
  }

  return { items: sortedItems, requestSort, sortConfig, cityFilter, parthnersFilter, statusFilter, filterFilter, cityfarr, parthnersfarr, statusfarr, filterfarr };
};


const Up = observer(() => {

  const [toast, setToast] = useState({ show: false, header: '', body: '', bgg: 'danger' });
  const [tagVisible, setTagVisible] = useState(false)
  const [tagUserLinkVisible, setTagUserLinkVisible] = useState(false)
  const [tagUsersVisible, setTagUsersVisible] = useState(false)
  const [modal, setModal] = useState({ show: false, header: '', body: '' });
  const navigate = useNavigate()


  const location = useLocation()

  let mounth = ''
  try {
    if (location.search.split('?')[1].split('&')[0].split('=')[0] === 'mounth') {
      mounth = location.search.split('?')[1].split('&')[0].split('=')[1]
    }
  } catch (error) {
    //console.error(error)
  }

  let date = new Date()
  // http://localhost:3000/sale1?startdate=2022-08-12&enddate=2022-08-12
  const [mounthN, setMounthN] = useState(date.getFullYear() + '-' + (('0' + (date.getMonth() + 1)).slice(-2)))
  if (mounth === '') {
    mounth = date.getFullYear() + '-' + (('0' + (date.getMonth() + 1)).slice(-2))
  }

  const [mounthD, setMounthD] = useState(mounth)
  const [tabs, setTabs] = useState([])
  const [table, setTable] = useState([])
  const [table2, setTable2] = useState([])
  const [table3, setTable3] = useState([])
  const [sales, setSales] = useState([])
  const [search, setSearch] = useState('')

  const [table1Filter, setTable1Filter] = useState([])

  const [editId, setEditId] = useState('')
  const [editId2, setEditId2] = useState('')
  const [editId3, setEditId3] = useState('')

  const [editParthner, setEditParthner] = useState('')
  const [editY, setEditY] = useState('')
  const [editDPC, setEditDPC] = useState(false)
  const [editDP, setEditDP] = useState('')
  const [editPrice, setEditPrice] = useState(0)

  const [editLidName, setEditLidName] = useState('')
  const [editLogistika, setEditLogistika] = useState('')

  const [editBank, setEditBank] = useState('')
  const [editPercet, setEditPercent] = useState('')
  const [editTerm, setEditTerm] = useState('')
  const [editDogovor, setEditDogovor] = useState('')
  const [editDO, setEditDO] = useState('')
  const [editManager, setEditManager] = useState('')
  const [editSMS, setEditSMS] = useState(0)
  const [editPOS, setEditPOS] = useState(0)
  const [editComission, setEditComission] = useState(0)
  const [editDI, setEditDI] = useState('')
  const [editColor, setEditColor] = useState('#ffffff')

  const [editParthner2, setEditParthner2] = useState('')
  const [editY2, setEditY2] = useState('')
  const [editDPC2, setEditDPC2] = useState(false)
  const [editDP2, setEditDP2] = useState('')
  const [editPrice2, setEditPrice2] = useState(0)

  const [editLidName2, setEditLidName2] = useState('')
  const [editLogistika2, setEditLogistika2] = useState('')

  const [editBank2, setEditBank2] = useState('')
  const [editPercet2, setEditPercent2] = useState('')
  const [editTerm2, setEditTerm2] = useState('')
  const [editDogovor2, setEditDogovor2] = useState('')
  const [editDO2, setEditDO2] = useState('')
  const [editManager2, setEditManager2] = useState('')
  const [editSMS2, setEditSMS2] = useState(0)
  const [editPOS2, setEditPOS2] = useState(0)
  const [editComission2, setEditComission2] = useState(0)
  const [editDI2, setEditDI2] = useState('')
  const [editColor2, setEditColor2] = useState('#ffffff')

  const [vozvratM, setVozvratM] = useState({ show: false })
  const [editVozvratDate, setEditVozvratDate] = useState('')
  const [editVozvratPrice, setEditVozvratPrice] = useState(0)
  const [editVozvratDI, setEditVozvratDI] = useState('')

  const [changes, setChangess] = useState({ show: false })
  const [changesStart, setChangesStart] = useState('')
  const [changesEnd, setChangesEnd] = useState('')
  const [changesSearch, setChangesSearch] = useState('')
  const [changesData, setChangesData] = useState([])


  const [trigger, setTrigger] = useState(true)
  const [trigger2, setTrigger2] = useState(true)
  const [trigger3, setTrigger3] = useState(true)
  const [loading, setLoading] = useState(true)


  const [parthnerList, setParthnerList] = useState([])
  const [yurList, setYurList] = useState([])
  const [logistikaList, setLogistikaList] = useState([])
  const [bankList, setBankList] = useState([])
  const [managerList, setManagerList] = useState([])

  const [tableHeadCsv, setTableHeadCsv] = useState([{
    key: "id",
    label: "ID"
  },
  {
    key: "crm2",
    label: "Филиал"
  },
  {
    key: "parthner",
    label: "Партнер (бренд/юр.лицо)"
  },
  {
    key: "parthner_id2",
    label: "ID партнера"
  },
  {
    key: "yur",
    label: "Юр. лицо"
  },
  {
    key: "dcpc",
    label: "Ден.средства поступ."
  },
  {
    key: "dcp",
    label: "Дата поступ. ден.средств"
  },
  {
    key: "crm_id",
    label: "ID сделки"
  },
  {
    key: "crm_href",
    label: "Ссылка сделки"
  },
  {
    key: "lid_name",
    label: "Оформленный договор(ФИО)"
  },
  {
    key: "logistika",
    label: "Логистика (накладная)"
  },
  {
    key: "do",
    label: "Дата одобр."
  },
  {
    key: "bank",
    label: "Банк"
  },
  {
    key: "percent",
    label: "% ставка"
  },
  {
    key: "term",
    label: "Cрок"
  },
  {
    key: "dogovor",
    label: "№ кред. договора"
  },
  {
    key: "price",
    label: "Размер кредита, руб"
  },
  {
    key: "price_sms",
    label: "СМС сервис(АК), руб"
  },
  {
    key: "price_summ",
    label: "Сумма без СМС"
  },
  {
    key: "price_pos",
    label: "СМС POS, руб"
  },
  {
    key: "comission",
    label: "Комиссия АК"
  },
  {
    key: "comission2",
    label: "Сумма без комисси"
  },
  {
    key: "manager",
    label: "Менеджер АК"
  },
  {
    key: "di",
    label: "Доп.Информация"
  }])

  const [tableHeadCsv2, setTableHeadCsv2] = useState([{
    key: "id",
    label: "ID"
  },
  {
    key: "crm2",
    label: "Филиал"
  },
  {
    key: "parthner",
    label: "Партнер (бренд/юр.лицо)"
  },
  {
    key: "parthner_id2",
    label: "ID партнера"
  },
  {
    key: "yur",
    label: "Юр. лицо"
  },
  {
    key: "dv",
    label: "Дата возврата"
  },
  {
    key: "pv",
    label: "Сумма возврата"
  },
  {
    key: "iv",
    label: "Инфо по возврату"
  },
  {
    key: "dcpc",
    label: "Ден.средства поступ."
  },
  {
    key: "dcp",
    label: "Дата поступ. ден.средств"
  },
  {
    key: "crm_id",
    label: "ID сделки"
  },
  {
    key: "crm_href",
    label: "Ссылка сделки"
  },
  {
    key: "lid_name",
    label: "Оформленный договор(ФИО)"
  },
  {
    key: "logistika",
    label: "Логистика (накладная)"
  },
  {
    key: "do",
    label: "Дата одобр."
  },
  {
    key: "bank",
    label: "Банк"
  },
  {
    key: "percent",
    label: "% ставка"
  },
  {
    key: "term",
    label: "Cрок"
  },
  {
    key: "dogovor",
    label: "№ кред. договора"
  },
  {
    key: "price",
    label: "Размер кредита, руб"
  },
  {
    key: "price_sms",
    label: "СМС сервис(АК), руб"
  },
  {
    key: "price_pos",
    label: "СМС POS, руб"
  },
  {
    key: "price_summ",
    label: "Сумма без СМС"
  },
  {
    key: "comission",
    label: "Комиссия АК"
  },
  {
    key: "comission2",
    label: "Сумма без комисси"
  },
  {
    key: "manager",
    label: "Менеджер АК"
  },
  {
    key: "di",
    label: "Доп.Информация"
  }])

  useEffect(() => {


    location.search = "?mounth=" + mounthD
    navigate(location)
    setLoading(true)
    setEditId('')
    setEditId2('')
    setEditId3('')
    let darray = []
    let d1 = new Date(mounthD.split('-')[0] * 1, mounthD.split('-')[1] * 1)
    d1.setMonth(d1.getMonth() - 4);

    for (let i = 0; i < 7; i++) {
      if (new Date(d1) < new Date(mounthN.split('-')[0] * 1, mounthN.split('-')[1] * 1)) {
        darray.push(new Date(d1).getFullYear() + '-' + (('0' + (new Date(d1).getMonth() + 1)).slice(-2)))
      }

      d1.setMonth(d1.getMonth() + 1);
    }



    setTabs(darray)


    fetchUp(mounthD).then((data) => {


      if (data.succsess) {
        let tbody = []
        Object.keys(data.succsess).map((item2, index2) => {
          if (item2 !== 'coll')
            Object.keys(data.succsess[item2].parthners).map((item3, index3) => {
              Object.keys(data.succsess[item2].parthners[item3].leads).map((item4, index4) => {
                let tr = {
                  id: "tr_" + item2 + '_' + item3 + '_' + item4,
                  city_id: item2,
                  parthner_id: item2 + "_" + item3,
                  parthner_id2: item3,
                  city: data.succsess[item2].name,
                  parthner: data.succsess[item2].parthners[item3].name,
                  lidName: data.succsess[item2].parthners[item3].leads[item4].name,
                  date: data.succsess[item2].parthners[item3].leads[item4].date,
                  manager: data.succsess[item2].parthners[item3].leads[item4].manager,
                  price: data.succsess[item2].parthners[item3].leads[item4].price,
                  leadId: item4,
                  href: data.succsess[item2].parthners[item3].leads[item4].href,
                }
                tbody.push(tr)
              })
            })
        })
        setSales(data);
        setTable(tbody)
        setLoading(false)
      } else {
        setSales(data.error);
        setLoading(false)
        // sales.setSale1(data.error);
      }


      // console.log(Object.keys(data).map(function(_) { return data[_]; }))


      // console.warn( location.search)

    });
  }, [trigger, mounthD]);


  useEffect(() => {


    fetchUpAll(mounthD).then((data) => {
      if (data.succsess) {

        setTable2(data.succsess)

        fetchLists().then((data2) => {
          if (data2.succsess) {

            setParthnerList(data2.succsess.p)
            setYurList(data2.succsess.y)
            setLogistikaList(data2.succsess.l)
            setBankList(data2.succsess.b)


            GetListOfBanks().then((data3) => {
              if (data3.error) {
                setBankList([])
              } else {
                setBankList(data3.success)
              }
            });
  
            setManagerList(data2.succsess.m)
          } else {

            setParthnerList([])
            setYurList([])
            setLogistikaList([])
           
            setManagerList([])
            setTable2([])
            // sales.setSale1(data.error);
          }


        });

        setParthnerList(getUnique(Array.from(data.succsess, ({ parthner }) => parthner)))
        setYurList(getUnique(Array.from(data.succsess, ({ yur }) => yur)))
        setLogistikaList(getUnique(Array.from(data.succsess, ({ logistika }) => logistika)))
        // setBankList(getUnique(Array.from(data.succsess, ({ bank }) => bank)))
        setManagerList(getUnique(Array.from(data.succsess, ({ manager }) => manager)))
      } else {

        setParthnerList([])
        setYurList([])
        setLogistikaList([])
        setBankList([])
        setManagerList([])
        setTable2([])
        // sales.setSale1(data.error);
      }


    });
  }, [trigger, mounthD, trigger2]);

  useEffect(() => {

    fetchUpAllV(mounthD).then((data) => {
      // console.log('VOZVRAT')
      // console.log(data)

      if (data.succsess) {

        setTable3(data.succsess)


      } else {

        setTable3([])
        // sales.setSale1(data.error);
      }


    });

    fetchUpAll2(mounthD).then((data) => {
      // console.log('VOZVRAT2')
      // console.log(data)

      if (data.succsess) {
        setTable1Filter(data.succsess)
      } else {
        setTable1Filter([])
      }


    });


  }, [trigger, mounthD, trigger2, trigger3]);





  useEffect(() => {
    if (editId != '') {
      let i1 = editId.split('_')

      setEditParthner(sales.succsess[i1[1]].parthners[i1[2]].name)
      setEditY('')
      setEditDPC(false)
      setEditDP(tim_to_date(sales.succsess[i1[1]].parthners[i1[2]].leads[i1[3]].date, 5))
      setEditDO(tim_to_date(sales.succsess[i1[1]].parthners[i1[2]].leads[i1[3]].date, 2))
      setEditPrice(sales.succsess[i1[1]].parthners[i1[2]].leads[i1[3]].price || 0)
      setEditLidName(sales.succsess[i1[1]].parthners[i1[2]].leads[i1[3]].name)
      setEditManager(sales.succsess[i1[1]].parthners[i1[2]].leads[i1[3]].manager || '')
      setEditLogistika('')

      setEditBank('')
      setEditPercent('')
      setEditTerm('')
      setEditDogovor(sales.succsess[i1[1]].parthners[i1[2]].leads[i1[3]].dogovor || '')
      // setEditDogovor('')
      setEditSMS(0)
      setEditPOS(0)
      setEditComission(0)
      setEditDI('')
      setEditColor('#ffffff')

    }

  }, [editId])

  useEffect(() => {
    if (editId2 != '') {
      // console.log(editId2)
      // let i1=editId.split('_')


      setEditParthner2(editId2.parthner)
      setEditY2(editId2.yur)
      setEditDPC2((editId2.dcpc == '1') ? true : false)
      setEditDP2(editId2.dcp)
      setEditDO2(editId2.do)
      setEditPrice2(editId2.price)
      setEditLidName2(editId2.lid_name)
      setEditManager2(editId2.manager)
      setEditLogistika2(editId2.logistika)

      setEditBank2(editId2.bank)
      setEditPercent2(editId2.percent)
      setEditTerm2(editId2.term)
      setEditDogovor2(editId2.dogovor)
      setEditSMS2(editId2.price_sms)
      setEditPOS2(editId2.price_pos)
      setEditComission2(editId2.comission)
      setEditDI2(editId2.di)
      setEditColor2(editId2.color)

    }

  }, [editId2])

  useEffect(() => {
    if (editId3 != '') {

      setEditVozvratDate(editId3.dv)
      setEditVozvratPrice(editId3.price)
      setEditVozvratDI(editId3.di)

    } else {
      setEditVozvratDate('')
      setEditVozvratPrice(0)
      setEditVozvratDI('')
    }

  }, [editId3])




  function tim_to_date(tim, variant) {
    if (tim == '') return ''
    let date = new Date(tim * 1000)
    let date2 = new Date(tim)
    let date3 = new Date((tim + 172800) * 1000)

    if (date.getDay() == 4 || date.getDay() == 5) {
      date3 = new Date((tim + 345600) * 1000)
    } else if (date.getDay() == 6) {
      date3 = new Date((tim + 259200) * 1000)
    }





    switch (variant) {
      case 1:
        return (('0' + date.getDate()).slice(-2)) + '.' + (('0' + (date.getMonth() + 1)).slice(-2)) + '.' + date.getFullYear()
      case 2:
        return date.getFullYear() + '-' + (('0' + (date.getMonth() + 1)).slice(-2)) + '-' + (('0' + date.getDate()).slice(-2))
      case 3:
        return tim.split('-')[2] + '.' + tim.split('-')[1] + '.' + tim.split('-')[0]
      case 4:
        return (('0' + date2.getDate()).slice(-2)) + '.' + (('0' + (date2.getMonth() + 1)).slice(-2)) + '.' + date2.getFullYear() + ' ' + date2.getHours() + ':' + date2.getMinutes()
      case 5:

        return date3.getFullYear() + '-' + (('0' + (date3.getMonth() + 1)).slice(-2)) + '-' + (('0' + date3.getDate()).slice(-2))

    }
  }

  async function add() {
    if (!editDO) {
      setToast({ show: true, header: "Внимание!!!", body: "Заполните дату одобрения!", bgg: 'danger' })
      return
    }
    let m = []
    m.push(<span style={{ color: "green" }} key="s1">Добавить в УП </span>)
    m.push(<b key="b1">{editLidName} <span style={{ color: "orange" }}> ({editParthner})</span>?</b>)
    if (await confirm(m)) {
      // alert(editBank)

      upCreate(
          editId,
          editId.split('_')[1],
          editParthner,
          editY,
          editDPC,
          editDP,
          editId.split('_')[3],
          sales.succsess[editId.split('_')[1]].parthners[editId.split('_')[2]].leads[editId.split('_')[3]].href,
          editLidName,
          editLogistika,
          editDO,
          editBank,
          editPercet,
          editTerm,
          editDogovor,
          editPrice,
          editSMS,
          editPOS,
          editComission,
          editManager,
          editDI,
          editColor
      ).then((data) => {
          if (data.succsess){
              setTrigger2(!trigger2)
              setToast({show:true, header:"Успешно!!!", body:"Сделка добавлена", bgg:'success'})
              // console.log(data.succsess)

          } else {
              // console.log(data)
              setTrigger2(!trigger2)
              setToast({show:true, header:"Внимание!!!", body:data.error, bgg:'danger'})
          }
      }); 


      console.log("YES")
      setEditId('')
    } else {
      console.log("NO")
      // setEditId('')
      // setTrigger2(!trigger2)
    }
  }

  async function edit(double = false, del = false) {
    if (!editDO2) {
      setToast({ show: true, header: "Внимание!!!", body: "Заполните дату одобрения!", bgg: 'danger' })
      return
    }
    let m = []
    if (!double)
      if (!del)
        m.push(<span style={{ color: "green" }} key="s1">Сохранить изменения в </span>)
      else
        m.push(<span style={{ color: "green" }} key="s1">Удалить </span>)
    else
      m.push(<span style={{ color: "green" }} key="s1">Сохранить как ДУБЛЬ </span>)
    m.push(<b key="b1">{editLidName2} <span style={{ color: "orange" }}> ({editParthner2})</span>?</b>)
    if (await confirm(m)) {
      upEdit(
        editId2.up_id,
        editParthner2,
        editY2,
        editDPC2,
        editDP2,
        editLidName2,
        editLogistika2,
        editDO2,
        editBank2,
        editPercet2,
        editTerm2,
        editDogovor2,
        editPrice2,
        editSMS2,
        editPOS2,
        editComission2,
        editManager2,
        editDI2,
        editColor2,
        double,
        del
      ).then((data) => {
        if (data.succsess) {
          setTrigger2(!trigger2)
          if (!double)
            if (!del)
              setToast({ show: true, header: "Успешно!!!", body: "Сделка отредактирована", bgg: 'success' })
            else
              setToast({ show: true, header: "Успешно!!!", body: "Сделка удалена", bgg: 'success' })
          else
            setToast({ show: true, header: "Успешно!!!", body: "Дубль сохранен", bgg: 'success' })
          // console.log(data.succsess)

        } else {
          // console.log(data)
          setTrigger2(!trigger2)
          setToast({ show: true, header: "Внимание!!!", body: data.error, bgg: 'danger' })
        }
      });


      console.log("YES")
      setEditId2('')
    } else {
      console.log("NO")
      // setEditId2('')
      // setTrigger2(!trigger2)
    }
  }

  async function edit2(double = false, del = false) {
    if (!editVozvratDate) {
      setToast({ show: true, header: "Внимание!!!", body: "Заполните дату возврата!", bgg: 'danger' })
      return
    }
    if (!editVozvratPrice) {
      setToast({ show: true, header: "Внимание!!!", body: "Заполните сумму возврата!", bgg: 'danger' })
      return
    }
    let m = []
    if (!double)
      if (!del)
        m.push(<span style={{ color: "green" }} key="s1">Сохранить изменения в </span>)
      else
        m.push(<span style={{ color: "green" }} key="s1">Удалить </span>)
    else
      m.push(<span style={{ color: "green" }} key="s1">Сохранить как ДУБЛЬ </span>)
    m.push(<b key="b1">{editId3.upv.lid_name} <span style={{ color: "orange" }}> ({editId3.upv.parthner})</span>?</b>)
    if (await confirm(m)) {
      upEdit2(
        editId3.id,
        editVozvratDate,
        editVozvratPrice,
        editVozvratDI,
        del
      ).then((data) => {
        if (data.succsess) {
          setTrigger3(!trigger3)
          if (!double)
            if (!del)
              setToast({ show: true, header: "Успешно!!!", body: "Возврат отредактирован", bgg: 'success' })
            else
              setToast({ show: true, header: "Успешно!!!", body: "Возврат удален", bgg: 'success' })
          else
            setToast({ show: true, header: "Успешно!!!", body: "Дубль сохранен", bgg: 'success' })
          // console.log(data.succsess)

        } else {
          // console.log(data)
          setTrigger3(!trigger3)
          setToast({ show: true, header: "Внимание!!!", body: data.error, bgg: 'danger' })
        }
      });


      console.log("YES")
      setEditId3('')
    } else {
      console.log("NO")
      // setEditId2('')
      // setTrigger2(!trigger2)
    }
  }

  async function vozvrat() {
    if (!editVozvratDate) {
      setToast({ show: true, header: "Внимание!!!", body: "Заполните дату возврата!", bgg: 'danger' })
      return
    }
    if (!editVozvratPrice) {
      setToast({ show: true, header: "Внимание!!!", body: "Заполните сумму возврата!", bgg: 'danger' })
      return
    }

    upCreateV(
      editId2.up_id,
      editVozvratDate,
      editVozvratPrice,
      editVozvratDI
    ).then((data) => {
      if (data.succsess) {
        setTrigger2(!trigger2)
        setToast({ show: true, header: "Успешно!!!", body: "Взвврат добавлен", bgg: 'success' })
        // console.log(data.succsess)      
        setVozvratM({ show: false })
        setEditId2('')

        setEditVozvratDate('')
        setEditVozvratPrice(0)
        setEditVozvratDI('')
      } else {
        // console.log(data)
        // setTrigger2(!trigger2)
        setToast({ show: true, header: "Внимание!!!", body: data.error, bgg: 'danger' })
      }
    })


    // console.log(editVozvratDate)
    // console.log(editVozvratPrice)
    // console.log(editVozvratDI)
  }

  async function getChanges() {
    getChangess(
      changesStart,
      changesEnd,
      changesSearch
    ).then((data) => {
      if (data.succsess) {
        // setTrigger2(!trigger2)
        setChangesData(data.succsess)
        setToast({ show: true, header: "Успешно!!!", body: "История подгружена", bgg: 'success' })
        // console.log(data.succsess)

      } else {
        // console.log(data)
        // setTrigger2(!trigger2)
        setToast({ show: true, header: "Внимание!!!", body: data.error, bgg: 'danger' })
      }
    })



  }


  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const { items, requestSort, sortConfig, cityFilter, parthnersFilter, statusFilter, filterFilter, cityfarr, parthnersfarr, statusfarr, filterfarr } = useSortableData(table2);

  function dataFromAsyncProcess() {
    let pattern = /"/g;
    let items2 = JSON.parse(JSON.stringify(items))
    items2.map((item4, index4) => {
      item4['crm2'] = regions2[item4.crm]
      item4['parthner_id2'] = item4.up_id.split("_")[2]
      item4['price_summ'] = item4.price - item4.price_sms
      item4['comission2'] = (item4.price - item4.price_sms) - ((item4.price - item4.price_sms) * item4.comission)
      item4['percent'] = item4.percent.toString().replace(".", ",");
      item4['parthner'] = item4.parthner.replace(pattern, '""');
      item4['yur'] = item4.yur.replace(pattern, '""');
    })


    return items2
  }

  function dataFromAsyncProcess2() {
    let pattern = /"/g;
    let items2 = JSON.parse(JSON.stringify(table3))
    items2.map((item4, index4) => {
      item4['crm2'] = regions2[item4.upv.crm]
      item4['parthner'] = item4.upv.parthner.replace(pattern, '""');
      item4['parthner_id2'] = item4.upv.up_id.split("_")[2]
      item4['yur'] = item4.upv.yur.replace(pattern, '""');
      item4['pv'] = item4.price
      item4['iv'] = item4.di
      item4['dcpc'] = item4.upv.dcpc
      item4['dcp'] = item4.upv.dcp
      item4['crm_id'] = item4.upv.crm_id
      item4['crm_href'] = item4.upv.crm_href
      item4['lid_name'] = item4.upv.lid_name
      item4['logistika'] = item4.upv.logistika
      item4['do'] = item4.upv.do
      item4['bank'] = item4.upv.bank
      item4['percent'] = item4.upv.percent.toString().replace(".", ",");
      item4['term'] = item4.upv.term
      item4['dogovor'] = item4.upv.dogovor
      item4['price'] = item4.upv.price
      item4['price_sms'] = item4.upv.price_sms
      item4['price_pos'] = item4.upv.price_pos
      item4['price_summ'] = item4.upv.price - item4.upv.price_sms
      item4['comission'] = item4.upv.comission
      item4['comission2'] = (item4.upv.price - item4.upv.price_sms) - ((item4.upv.price - item4.upv.price_sms) * item4.upv.comission)
      item4['manager'] = item4.upv.manager
      item4['di'] = item4.upv.di
    })


    return items2
  }


  return (

    <Container className="d-flex flex-column" style={{ paddingBottom: "100px" }}>
      {console.log('render')}
      <Breadcrumb>
        <NavLink className="breadcrumb-item" to={INDEX_ROUTE}>Главная</NavLink>
        <Breadcrumb.Item active>Учет продаж</Breadcrumb.Item>
      </Breadcrumb>

      <Nav fill variant="tabs" defaultActiveKey={"link-" + mounthD}>
        {tabs.map((item, index) => (
          <Nav.Item key={item}>
            <Nav.Link
              disabled={loading}
              eventKey={"link-" + item}
              onClick={() => setMounthD(item)}

            >
              {item}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      {(editId == '' && editId2 == '' && editId3 == '' && !loading) ?
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">🔎</InputGroup.Text>
          <Form.Control
            placeholder="Поиск"
            aria-label="Поиск"
            aria-describedby="basic-addon1"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <Button
            variant="outline-secondary"
            id="button-addon1"
            onClick={e => { setSearch(''); }}
          >
            ❌
          </Button>
        </InputGroup> : ''}
      {(editId2 == '' && editId3 == '') ?
        <div style={{ overflow: "auto", maxHeight: "300px" }}>
          {(sales['succsess']) ?
            <Table style={{ overflow: "auto", fontSize: "0.8em" }} variant={(sales['succsess']) ? "light" : "danger"} responsive="md" bordered striped hover className={(editId == '') ? "sale5table" : ''}>
              <thead>
                <tr>
                  {/* <th  className={"text-center"}>
                                ##
                            </th> */}

                  <th className={"text-center"}>
                    Филиал
                  </th>
                  <th className={"text-center"}>
                    Партнер (бренд/юр.лицо)
                  </th>

                  {(editId !== '') ? <th className={"text-center"}>
                    Юр. лицо
                  </th> : ''
                  }
                  {(editId !== '') ? <th className={"text-center"}>
                    Ден.средства поступ.
                  </th> : ''
                  }

                  <th className={"text-center"}>
                    ID сделки
                  </th>
                  <th className={"text-center"}>
                    Оформленный договор(ФИО)
                  </th>
                  {(editId !== '') ? <th className={"text-center"}>
                    Логистика (накладная)
                  </th> : ''
                  }
                  <th className={"text-center"}>
                    Дата одобр.
                  </th>
                  {(editId !== '') ? <th className={"text-center"}>
                    Банк
                  </th> : ''
                  }
                  {(editId !== '') ? <th className={"text-center"}>
                    % ставка
                  </th> : ''
                  }
                  {(editId !== '') ? <th className={"text-center"}>
                    Cрок
                  </th> : ''
                  }
                  <th className={"text-center"}>
                    № кред. договора
                  </th>
                  <th className={"text-center"}>
                    Размер кредита, руб
                  </th>
                  {(editId !== '') ? <th className={"text-center"}>
                    СМС сервис(АК), руб
                  </th> : ''
                  }
                  {(editId !== '') ? <th className={"text-center"}>
                    СМС POS, руб
                  </th> : ''
                  }
                  {(editId !== '') ? <th className={"text-center"}>
                    Сумма без СМС
                  </th> : ''
                  }
                  {(editId !== '') ? <th className={"text-center"}>
                    Комиссия АК
                  </th> : ''
                  }
                  {(editId !== '') ? <th className={"text-center"}>
                    Сумма за вычетом комисси
                  </th> : ''
                  }
                  <th className={"text-center"}>
                    Менеджер АК
                  </th>
                  {(editId !== '') ? <th className={"text-center"}>
                    Доп.Информация
                  </th> : ''
                  }
                  {(editId !== '') ? <th className={"text-center"}>
                    Цвет
                  </th> : ''
                  }
                </tr>
              </thead>
              {(!loading) ?
                <tbody>

                  {(editId == '') ?
                    table.map(tr => (
                      ((tr.parthner.toLowerCase().includes(search.toLowerCase())
                        ||
                        tr.leadId.toLowerCase().includes(search.toLowerCase())
                        ||
                        tr.lidName.toLowerCase().includes(search.toLowerCase())
                      ) && (!items.find(trr => trr.up_id.split('_')[3].replace(/\*/gi, '') == tr.id.split('_')[3].replace(/\*/gi, '')))
                        // ||
                        // tr.manager.toLowerCase().includes(search.toLowerCase())
                        && !table1Filter.includes(tr.id.split('_')[3])
                      ) ?
                        <tr key={tr.id} key2={tr.id} onDoubleClick={() => setEditId(tr.id)}>
                          {/* <td 
                                        className="text-center"
                                        style={{cursor:"pointer"}}
                                        onClick={() =>  setEditId(tr.id)  }
                                    >🛒</td>   */}
                          <td
                            className="text-center"
                          >{tr.city}</td>
                          <td
                            className="text-center"
                          >
                            {tr.parthner}
                          </td>
                          <td
                            className="text-center"
                          >
                            <a target="_blank" href={tr.href}>{tr.leadId}</a>
                          </td>
                          <td
                            className="text-center"
                          >
                            {tr.lidName}
                          </td>

                          <td
                            className="text-center"
                          >{tim_to_date(tr.date, 1)}</td>
                          <td
                            className="text-center"
                          >{tr.dogovor}</td>
                          <td
                            className="text-center"
                          >{priceSet(tr.price)}</td>
                          <td
                            className="text-center"
                          >{tr.manager}</td>
                        </tr> : ''
                    )) :
                    <tr key={editId}>
                      {/* <td 
                                        className="text-center"
                                        style={{cursor:"pointer"}}
                                        onClick={() =>  add()  }
                                    >✅</td>   */}
                      <td
                        className="text-center"
                      >{sales.succsess[editId.split('_')[1]].name}</td>
                      <td
                        className="text-center"
                      >

                        <Form.Control
                          style={{ fontSize: "1em", width: editParthner.length + 10 + 'ch' }}
                          value={editParthner}
                          size="sm"
                          type="text"
                          onChange={e => setEditParthner(e.target.value)}
                          list="pList"
                        />
                        <datalist id="pList">
                          {parthnerList.map(tr => (
                            <option key={tr} value={tr} />
                          ))}
                        </datalist>
                      </td>
                      <td
                        className="text-center"
                      >

                        <Form.Control
                          style={{ fontSize: "1em", width: editY.length + 10 + 'ch' }}
                          value={editY}
                          size="sm"
                          type="text"
                          onChange={e => setEditY(e.target.value)}
                          list="yList"
                        />
                        <datalist id="yList">
                          {yurList.map(tr => (
                            <option key={tr} value={tr} />
                          ))}
                        </datalist>
                      </td>
                      <td
                        className="text-center"
                      >
                        <InputGroup style={{ flexWrap: "inherit" }} className="">
                          <InputGroup.Checkbox
                            checked={editDPC}
                            onChange={e => setEditDPC(!editDPC)}
                          />
                          <Form.Control
                            value={editDP}
                            onChange={e => setEditDP(e.target.value)}
                            type="date"
                            disabled={editDPC}
                            size="sm"
                          />
                        </InputGroup>


                      </td>
                      <td
                        className="text-center"
                      >
                        <a target="_blank" href={sales.succsess[editId.split('_')[1]].parthners[editId.split('_')[2]].leads[editId.split('_')[3]].href}>{editId.split('_')[3]}</a>
                      </td>
                      <td
                        className="text-center"
                      >

                        <Form.Control
                          style={{ fontSize: "1em", width: editLidName.length + 10 + 'ch' }}
                          value={editLidName}
                          size="sm"
                          type="text"
                          onChange={e => setEditLidName(e.target.value)}
                        />

                      </td>
                      <td
                        className="text-center"
                      >

                        <Form.Control
                          style={{ fontSize: "1em", width: editLogistika.length + 10 + 'ch' }}
                          value={editLogistika}
                          size="sm"
                          type="text"
                          onChange={e => setEditLogistika(e.target.value)}
                          list="lList"
                        />
                        <datalist id="lList">
                          {logistikaList.map(tr => (
                            <option key={tr} value={tr} />
                          ))}
                        </datalist>

                      </td>
                      <td
                        className="text-center"
                      >
                        <Form.Control
                          value={editDO}
                          onChange={e => setEditDO(e.target.value)}
                          type="date"
                          size="sm"
                        />

                      </td>
                      <td
                        className="text-center"
                      >
                        <SelectReact  onChange={e =>setEditBank(e.value)} dvalue={editBank} list={bankList} />
                        {/* <Form.Control
                          style={{ fontSize: "1em", width: editBank.length + 10 + 'ch' }}
                          value={editBank}
                          size="sm"
                          type="text"
                          onChange={e => setEditBank(e.target.value)}
                          list="bList"
                        />
                        <datalist id="bList">
                          {bankList.map(tr => (
                            <option key={tr} value={tr} />
                          ))}
                        </datalist> */}

                      </td>
                      <td
                        className="text-center"
                      >

                        <Form.Control
                          style={{ fontSize: "1em", width: 10 + 'ch' }}
                          value={editPercet}
                          size="sm"
                          type="number"
                          onChange={e => setEditPercent(e.target.value)}
                        />

                      </td>
                      <td
                        className="text-center"
                      >

                        <Form.Control
                          style={{ fontSize: "1em", width: 10 + 'ch' }}
                          value={editTerm}
                          size="sm"
                          type="number"
                          onChange={e => setEditTerm(e.target.value)}
                        />

                      </td>
                      <td
                        className="text-center"
                      >

                        <Form.Control
                          style={{ fontSize: "1em", width: editDogovor.length + 10 + 'ch' }}
                          value={editDogovor}
                          size="sm"
                          type="text"
                          onChange={e => setEditDogovor(e.target.value)}
                        />

                      </td>
                      <td
                        className="text-center"
                      >
                        <Form.Control
                          style={{ fontSize: "1em", width: 20 + 'ch' }}
                          value={editPrice}
                          size="sm"
                          type="number"
                          onChange={e => setEditPrice(e.target.value * 1)}
                        />
                      </td>
                      <td
                        className="text-center"
                      >
                        <Form.Control
                          style={{ fontSize: "1em", width: 20 + 'ch' }}
                          value={editSMS}
                          size="sm"
                          type="number"
                          onChange={e => setEditSMS(e.target.value * 1)}
                        />
                      </td>
                      <td
                        className="text-center"
                      >
                        <Form.Control
                          style={{ fontSize: "1em", width: 20 + 'ch' }}
                          value={editPOS}
                          size="sm"
                          type="number"
                          onChange={e => setEditPOS(e.target.value * 1)}
                        />
                      </td>

                      <td
                        className="text-center"
                      >{priceSet(editPrice - editSMS)}</td>
                      <td
                        className="text-center"
                      >
                        <Form.Control
                          style={{ fontSize: "1em", width: 20 + 'ch' }}
                          value={editComission}
                          size="sm"
                          type="number"
                          onChange={e => setEditComission(e.target.value * 1)}
                        />
                      </td>
                      <td
                        className="text-center"
                      >{priceSet((editPrice - editSMS) - ((editPrice - editSMS) * editComission))}</td>
                      {/* <td 
                                        className="text-center"
                                    >#</td> */}
                      <td
                        className="text-center"
                      >
                        <Form.Control
                          style={{ fontSize: "1em", width: editManager.length + 10 + 'ch' }}
                          value={editManager}
                          size="sm"
                          type="text"
                          onChange={e => setEditManager(e.target.value)}
                          list="mList"
                        />
                        <datalist id="mList">
                          {managerList.map(tr => (
                            <option key={tr} value={tr} />
                          ))}
                        </datalist>
                      </td>
                      <td
                        className="text-center"
                      >

                        <Form.Control
                          style={{ fontSize: "1em", width: editDI.length + 10 + 'ch' }}
                          value={editDI}
                          size="sm"
                          type="text"
                          onChange={e => setEditDI(e.target.value)}
                        />

                      </td>
                      <td
                        className="text-center"
                      >
                        <Form.Control
                          type="color"
                          // defaultValue="#563d7c"
                          value={editColor}
                          onChange={e => setEditColor(e.target.value)}
                          title="Выберите цвет заливки..."
                        />
                        <Button onClick={e => setEditColor('#ffffff')} variant="secondary"> </Button>
                        <Button onClick={e => setEditColor('#0d6efd')} variant="primary"> </Button>
                        <Button onClick={e => setEditColor('#198754')} variant="success"> </Button>
                        <Button onClick={e => setEditColor('#dc3545')} variant="danger"> </Button>
                        <Button onClick={e => setEditColor('#ffc107')} variant="warning"> </Button>
                      </td>
                    </tr>

                  }
                </tbody> :
                <tbody>
                  <tr>
                    <td colSpan={16} className="text-center">
                      <Placeholder animation="glow">
                        <Placeholder xs={2} />
                      </Placeholder>
                    </td>
                  </tr>
                </tbody>

              }
            </Table>
            :

            <Table style={{ overflow: "auto", fontSize: "0.8em" }} variant={(sales['succsess']) ? "light" : "danger"} responsive="md" bordered striped hover className={(editId == '') ? "sale5table" : ''}>

              {(!loading) ?
                <tbody>
                  <tr>
                    <td colSpan={16} className="text-center">
                      {sales}
                    </td>
                  </tr>
                </tbody> :
                <tbody>
                  <tr>
                    <td colSpan={16} className="text-center">
                      <Placeholder animation="glow">
                        <Placeholder xs={2} />
                      </Placeholder>
                    </td>
                  </tr>
                </tbody>}
            </Table>

          }



        </div> : ''}
      {(editId != '' && !loading) ?
        <center>
          <Button size="sm" className="m-1" onClick={() => add()} variant="success">Сохранить</Button>
          <Button size="sm" className="m-1" onClick={() => setEditId('')} variant="danger">Отменить</Button>
        </center>
        : ''}
      {(editId2 == '' && editId3 == '') ?
        <>

          <hr />
          {(!loading) ?
            <>
              <CSVLink style={{ margin: "0px", padding: "3px 10px", textDecoration: "underline", color: "black", fontWeight: 600, display: 'contents' }} data={dataFromAsyncProcess()} filename={mounthD + '_leads.csv'} separator={";"} headers={tableHeadCsv}>Сделки в CSV</CSVLink>{' | '}
              <a style={{ margin: "0px", padding: "3px 10px", textDecoration: "underline", color: "black", fontWeight: 600, display: 'contents', cursor: "pointer" }} size="sm" className="m-1" onClick={() => setChangess({ show: true })} variant="primary">Изменения</a>
            </>
            : ''
          }
        </>
        : ''}
      {(editId == '' && editId3 == '') ? <div style={{ overflow: "auto", maxHeight: "300px" }}>
        <Table style={{ overflow: "auto", fontSize: "0.7em" }} variant={(table2) ? "light" : "danger"} responsive="md" bordered hover className="sale6table">
          <thead>
            <tr>
              {/* <th 
                             key='id'
                             title='id'
                             onClick={() => {requestSort('id');}}
                             className={"text-center "+getClassNamesFor('id')}
                             style={{paddingRight:"20px",cursor:"pointer"}}>
                                ##
                            </th> */}

              <th key='crm'
                title='crm'
                onClick={() => { requestSort('crm'); }}
                className={"text-center " + getClassNamesFor('crm')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Филиал
              </th>
              <th key='parthner'
                title='parthner'
                onClick={() => { requestSort('parthner'); }}
                className={"text-center " + getClassNamesFor('parthner')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Партнер (бренд/юр.лицо)
              </th>

              <th key='yur'
                title='yur'
                onClick={() => { requestSort('yur'); }}
                className={"text-center " + getClassNamesFor('yur')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Юр. лицо
              </th>
              <th key='dcp'
                title='dcp'
                onClick={() => { requestSort('dcp'); }}
                className={"text-center " + getClassNamesFor('dcp')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Ден.средства поступ.
              </th>
              <th key='crm_id'
                title='crm_id'
                onClick={() => { requestSort('crm_id'); }}
                className={"text-center " + getClassNamesFor('crm_id')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                ID сделки
              </th>
              <th key='lid_name'
                title='lid_name'
                onClick={() => { requestSort('lid_name'); }}
                className={"text-center " + getClassNamesFor('lid_name')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Оформленный договор(ФИО)
              </th>
              <th key='logistika'
                title='logistika'
                onClick={() => { requestSort('logistika'); }}
                className={"text-center " + getClassNamesFor('logistika')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Логистика (накладная)
              </th>
              <th key='do'
                title='do'
                onClick={() => { requestSort('do'); }}
                className={"text-center " + getClassNamesFor('do')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Дата одобр.
              </th>
              <th key='bank'
                title='bank'
                onClick={() => { requestSort('bank'); }}
                className={"text-center " + getClassNamesFor('bank')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Банк
              </th>
              <th key='percent'
                title='percent'
                onClick={() => { requestSort('percent'); }}
                className={"text-center " + getClassNamesFor('percent')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                % ставка
              </th>
              <th key='term'
                title='term'
                onClick={() => { requestSort('term'); }}
                className={"text-center " + getClassNamesFor('term')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Cрок
              </th>
              <th key='dogovor'
                title='dogovor'
                onClick={() => { requestSort('dogovor'); }}
                className={"text-center " + getClassNamesFor('dogovor')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                № кред. договора
              </th>
              <th key='price'
                title='price'
                onClick={() => { requestSort('price'); }}
                className={"text-center " + getClassNamesFor('price')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Размер кредита, руб
              </th>
              <th key='price_sms'
                title='price_sms'
                onClick={() => { requestSort('price_sms'); }}
                className={"text-center " + getClassNamesFor('price_sms')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                СМС сервис(АК), руб
              </th>
              <th className={"text-center"}>
                СМС POS, руб
              </th>
              <th className={"text-center"}>
                Сумма без СМС
              </th>
              <th className={"text-center"}>
                Комиссия АК
              </th>
              <th className={"text-center"}>
                Сумма без комисси
              </th>
              <th key='manager'
                title='manager'
                onClick={() => { requestSort('manager'); }}
                className={"text-center " + getClassNamesFor('manager')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Менеджер АК
              </th>
              <th key='di'
                title='di'
                onClick={() => { requestSort('di'); }}
                className={"text-center " + getClassNamesFor('di')}
                style={{ paddingRight: "20px", cursor: "pointer" }}>
                Доп.Информация
              </th>
              {
                (editId2 != '') ?
                  <th key='color'
                    title='color'
                    onClick={() => { requestSort('color'); }}
                    className={"text-center " + getClassNamesFor('color')}
                    style={{ paddingRight: "20px", cursor: "pointer" }}>
                    Цвет
                  </th> : ''
              }
            </tr>
          </thead>
          {(!loading) ?
            <tbody>
              {(editId2 == '') ? items.map((tr, index) => (
                (tr.parthner.toLowerCase().includes(search.toLowerCase())
                  ||
                  tr.crm_id.toLowerCase().includes(search.toLowerCase())
                  ||
                  tr.lid_name.toLowerCase().includes(search.toLowerCase())
                  ||
                  tr.dogovor.toLowerCase().includes(search.toLowerCase())
                  // ||
                  // tr.manager.toLowerCase().includes(search.toLowerCase())
                ) ?
                  <tr style={{ backgroundColor: tr.color }} key={tr.id} key2={tr.id} onDoubleClick={() => setEditId2(tr)}>
                    {/* <td 
                                                className="text-center"
                                            >{tr.id}</td>   */}
                    <td
                      className="text-center"
                    >{regions2[tr.crm]}</td>
                    <td
                      className="text-center"
                    >{tr.parthner}</td>
                    <td
                      className="text-center"
                    >{tr.yur}</td>
                    <td title={(tr.dcpc == 1) ? tim_to_date(tr.dcp, 3) : ''}
                      className="text-center"
                    >{(tr.dcpc == 1) ? '✅' : tim_to_date(tr.dcp, 3)}</td>

                    <td
                      className="text-center"
                    > <a target="_blank" href={tr.crm_href}>{tr.crm_id}</a></td>
                    <td
                      className="text-center"
                    >{tr.lid_name}</td>
                    <td
                      className="text-center"
                    >{tr.logistika}</td>
                    <td
                      className="text-center"
                    >{tim_to_date(tr.do, 3)}</td>
                    <td
                      className="text-center"
                    >{tr.bank}</td>
                    <td
                      className="text-center"
                    >{tr.percent}</td>
                    <td
                      className="text-center"
                    >{tr.term}</td>
                    <td
                      className="text-center"
                    >{tr.dogovor}</td>
                    <td
                      className="text-center"
                    >{priceSet(tr.price)}</td>
                    <td
                      className="text-center"
                    >{priceSet(tr.price_sms)}</td>
                    <td
                      className="text-center"
                    >{priceSet(tr.price_pos)}</td>
                    <td
                      className="text-center"
                    >{priceSet(tr.price - tr.price_sms)}</td>
                    <td
                      className="text-center"
                    >{priceSet(tr.comission)}</td>
                    <td
                      className="text-center"
                    >{priceSet((tr.price - tr.price_sms) - ((tr.price - tr.price_sms) * tr.comission))}</td>
                    <td
                      className="text-center"
                    >{tr.manager}</td>
                    <td
                      className="text-center"
                    >{tr.di}</td>
                  </tr> : ''

              )) :
                <tr key={editId2.id}>
                  {/* <td 
                                        className="text-center"
                                    >{editId2.id}</td>   */}
                  <td
                    className="text-center"
                  >{regions2[editId2.crm]}</td>
                  <td
                    className="text-center"
                  >

                    <Form.Control
                      style={{ fontSize: "1em", width: editParthner2.length + 10 + 'ch' }}
                      value={editParthner2}
                      size="sm"
                      type="text"
                      onChange={e => setEditParthner2(e.target.value)}
                      list="pList2"
                    />
                    <datalist id="pList2">
                      {parthnerList.map(tr => (
                        <option key={tr} value={tr} />
                      ))}
                    </datalist>
                  </td>
                  <td
                    className="text-center"
                  >

                    <Form.Control
                      style={{ fontSize: "1em", width: editY2.length + 10 + 'ch' }}
                      value={editY2}
                      size="sm"
                      type="text"
                      onChange={e => setEditY2(e.target.value)}
                      list="yList2"
                    />
                    <datalist id="yList2">
                      {yurList.map(tr => (
                        <option key={tr} value={tr} />
                      ))}
                    </datalist>
                  </td>
                  <td
                    className="text-center"
                  >
                    <InputGroup style={{ flexWrap: "inherit" }} className="">
                      <InputGroup.Checkbox
                        checked={editDPC2}
                        onChange={e => setEditDPC2(!editDPC2)}
                      />
                      <Form.Control
                        value={editDP2}
                        onChange={e => setEditDP2(e.target.value)}
                        type="date"
                        disabled={editDPC2}
                        size="sm"
                      />
                    </InputGroup>


                  </td>

                  <td
                    className="text-center"
                  > <a target="_blank" href={editId2.crm_href}>{editId2.crm_id}</a></td>
                  <td
                    className="text-center"
                  >

                    <Form.Control
                      style={{ fontSize: "1em", width: editLidName2.length + 10 + 'ch' }}
                      value={editLidName2}
                      size="sm"
                      type="text"
                      onChange={e => setEditLidName2(e.target.value)}
                    />

                  </td>
                  <td
                    className="text-center"
                  >

                    <Form.Control
                      style={{ fontSize: "1em", width: editLogistika2.length + 10 + 'ch' }}
                      value={editLogistika2}
                      size="sm"
                      type="text"
                      onChange={e => setEditLogistika2(e.target.value)}
                      list="lList2"
                    />
                    <datalist id="lList2">
                      {logistikaList.map(tr => (
                        <option key={tr} value={tr} />
                      ))}
                    </datalist>

                  </td>
                  <td
                    className="text-center"
                  >
                    <Form.Control
                      value={editDO2}
                      onChange={e => setEditDO2(e.target.value)}
                      type="date"
                      size="sm"
                    />

                  </td>
                  <td
                    className="text-center"
                  >
                    <SelectReact onChange={e =>setEditBank2(e.value)} dvalue={editBank2} list={bankList} />

                    {/* <Form.Control
                      style={{ fontSize: "1em", width: editBank2.length + 10 + 'ch' }}
                      value={editBank2}
                      size="sm"
                      type="text"
                      onChange={e => setEditBank2(e.target.value)}
                      list="bList2"
                    />
                    <datalist id="bList2">
                      {bankList.map(tr => (
                        <option key={tr} value={tr} />
                      ))}
                    </datalist> */}

                  </td>
                  <td
                    className="text-center"
                  >

                    <Form.Control
                      style={{ fontSize: "1em", width: 10 + 'ch' }}
                      value={editPercet2}
                      size="sm"
                      type="number"
                      onChange={e => setEditPercent2(e.target.value)}
                    />

                  </td>
                  <td
                    className="text-center"
                  >

                    <Form.Control
                      style={{ fontSize: "1em", width: 10 + 'ch' }}
                      value={editTerm2}
                      size="sm"
                      type="number"
                      onChange={e => setEditTerm2(e.target.value)}
                    />

                  </td>
                  <td
                    className="text-center"
                  >

                    <Form.Control
                      style={{ fontSize: "1em", width: editDogovor2.length + 10 + 'ch' }}
                      value={editDogovor2}
                      size="sm"
                      type="text"
                      onChange={e => setEditDogovor2(e.target.value)}
                    />

                  </td>
                  <td
                    className="text-center"
                  >
                    <Form.Control
                      style={{ fontSize: "1em", width: 20 + 'ch' }}
                      value={editPrice2}
                      size="sm"
                      type="number"
                      onChange={e => setEditPrice2(e.target.value * 1)}
                    />
                  </td>
                  <td
                    className="text-center"
                  >
                    <Form.Control
                      style={{ fontSize: "1em", width: 20 + 'ch' }}
                      value={editSMS2}
                      size="sm"
                      type="number"
                      onChange={e => setEditSMS2(e.target.value * 1)}
                    />
                  </td>
                  <td
                    className="text-center"
                  >
                    <Form.Control
                      style={{ fontSize: "1em", width: 20 + 'ch' }}
                      value={editPOS2}
                      size="sm"
                      type="number"
                      onChange={e => setEditPOS2(e.target.value * 1)}
                    />
                  </td>
                  <td
                    className="text-center"
                  >{priceSet(editPrice2 - editSMS2)}</td>
                  <td
                    className="text-center"
                  >
                    <Form.Control
                      style={{ fontSize: "1em", width: 20 + 'ch' }}
                      value={editComission2}
                      size="sm"
                      type="number"
                      onChange={e => setEditComission2(e.target.value * 1)}
                    />
                  </td>



                  <td
                    className="text-center"
                  >{priceSet((editPrice2 - editSMS2) - ((editPrice2 - editSMS2) * editComission2))}</td>


                  {/* <td 
                                        className="text-center"
                                    >#</td> */}
                  <td
                    className="text-center"
                  >
                    <Form.Control
                      style={{ fontSize: "1em", width: editManager2.length + 10 + 'ch' }}
                      value={editManager2}
                      size="sm"
                      type="text"
                      onChange={e => setEditManager2(e.target.value)}
                      list="mList2"
                    />
                    <datalist id="mList2">
                      {managerList.map(tr => (
                        <option key={tr} value={tr} />
                      ))}
                    </datalist>
                  </td>
                  <td
                    className="text-center"
                  >

                    <Form.Control
                      style={{ fontSize: "1em", width: editDI2.length + 10 + 'ch' }}
                      value={editDI2}
                      size="sm"
                      type="text"
                      onChange={e => setEditDI2(e.target.value)}
                    />

                  </td>
                  <td
                    className="text-center"
                  >
                    <Form.Control
                      type="color"
                      // defaultValue="#563d7c"
                      value={editColor2}
                      onChange={e => setEditColor2(e.target.value)}
                      title="Выберите цвет заливки..."
                    />
                    <Button onClick={e => setEditColor2('#ffffff')} variant="secondary"> </Button>
                    <Button onClick={e => setEditColor2('#0d6efd')} variant="primary"> </Button>
                    <Button onClick={e => setEditColor2('#198754')} variant="success"> </Button>
                    <Button onClick={e => setEditColor2('#dc3545')} variant="danger"> </Button>
                    <Button onClick={e => setEditColor2('#ffc107')} variant="warning"> </Button>

                  </td>
                </tr>}
            </tbody> :
            <tbody>
              <tr>
                <td colSpan={21} className="text-center">
                  <Placeholder animation="glow">
                    <Placeholder xs={2} />
                  </Placeholder>
                </td>
              </tr>
            </tbody>
          }
        </Table>
      </div> : ''}
      {(editId2 != '' && !loading) ?
        <center>
          <Button size="sm" className="m-1" onClick={() => edit()} variant="success">Сохранить</Button>
          <Button size="sm" className="m-1" onClick={() => edit(true)} variant="info">Сохранить как дубль</Button>
          <Button size="sm" className="m-1" onClick={() => setVozvratM({ show: true })} variant="primary">Возврат</Button>
          <Button size="sm" className="m-1" onClick={() => edit(false, true)} variant="warning">Удалить</Button>
          <Button size="sm" className="m-1" onClick={() => setEditId2('')} variant="danger">Отмена</Button>
        </center>
        : ''}
      {(editId2 == '' && editId == '') ?
        <>
          {(!loading) ?
            sumSalaries(items) : ''}
          <hr />
          {(!loading) ?

            <CSVLink style={{ margin: "0px", padding: "3px 10px", textDecoration: "underline", color: "black", fontWeight: 600, display: 'contents' }} data={dataFromAsyncProcess2()} filename={mounthD + '_returns.csv'} separator={";"} headers={tableHeadCsv2}>Возвраты в CSV</CSVLink>
            : ''
          }
        </>
        : ''}




      {(editId2 == '' && editId == '') ?
        <div style={{ overflow: "auto", maxHeight: "300px" }}>
          <Table style={{ overflow: "auto", fontSize: "0.7em" }} variant={(table3) ? "light" : "danger"} responsive="md" bordered striped hover className={(editId3 == '') ? "sale7table" : ''}>
            <thead>
              <tr>
                {/* <th  className={"text-center"}>
                                ##
                            </th> */}

                <th className={"text-center"}>
                  Филиал
                </th>
                <th className={"text-center"}>
                  Партнер (бренд/юр.лицо)
                </th>

                <th className={"text-center"}>
                  Юр. лицо
                </th>
                <th className={"text-center"}>
                  Дата возврата
                </th>
                <th className={"text-center"}>
                  Сумма возврата
                </th>
                <th className={"text-center"}>
                  Инфо по возврату
                </th>

                <th className={"text-center"}>
                  Ден.средства поступ.
                </th>

                <th className={"text-center"}>
                  ID сделки
                </th>
                <th className={"text-center"}>
                  Оформленный договор(ФИО)
                </th>
                <th className={"text-center"}>
                  Логистика (накладная)
                </th>
                <th className={"text-center"}>
                  Дата одобр.
                </th>
                <th className={"text-center"}>
                  Банк
                </th>
                <th className={"text-center"}>
                  % ставка
                </th>
                <th className={"text-center"}>
                  Cрок
                </th>
                <th className={"text-center"}>
                  № кред. договора
                </th>
                <th className={"text-center"}>
                  Размер кредита, руб
                </th>
                <th className={"text-center"}>
                  СМС сервис(АК), руб
                </th>
                <th className={"text-center"}>
                  СМС POS, руб
                </th>
                <th className={"text-center"}>
                  Сумма без СМС
                </th>
                <th className={"text-center"}>
                  Менеджер АК
                </th>
                <th className={"text-center"}>
                  Доп.Информация
                </th>

              </tr>
            </thead>
            {(!loading) ?
              <tbody>
                {(editId3 == '') ?
                  table3.map(tr => (
                    ((tr.upv.parthner.toLowerCase().includes(search.toLowerCase())
                      ||
                      tr.upv.crm_id.toLowerCase().includes(search.toLowerCase())
                      ||
                      tr.upv.lid_name.toLowerCase().includes(search.toLowerCase())
                      ||
                      tr.upv.dogovor.toLowerCase().includes(search.toLowerCase())
                    )
                      // ||
                      // tr.manager.toLowerCase().includes(search.toLowerCase())
                    ) ?
                      <tr key={tr.id} onDoubleClick={() => setEditId3(tr)}>
                        <td
                          className="text-center"
                        >{regions2[tr.upv.crm]}</td>
                        <td
                          className="text-center"
                        >
                          {tr.upv.parthner}
                        </td>
                        <td
                          className="text-center"
                        >
                          {tr.upv.yur}
                        </td>
                        <td
                          className="text-center"
                        >{tim_to_date(tr.dv, 3)}</td>
                        <td
                          className="text-center"
                        >{priceSet(tr.price)}</td>
                        <td
                          className="text-center"
                        >
                          {tr.di}
                        </td>
                        <td title={(tr.upv.dcpc == 1) ? tim_to_date(tr.upv.dcp, 3) : ''}
                          className="text-center"
                        >{(tr.upv.dcpc == 1) ? '✅' : tim_to_date(tr.upv.dcp, 3)}</td>
                        <td
                          className="text-center"
                        >
                          <a target="_blank" href={tr.upv.crm_href}>{tr.upv.crm_id}</a>
                        </td>
                        <td
                          className="text-center"
                        >
                          {tr.upv.lid_name}
                        </td>
                        <td
                          className="text-center"
                        >{tr.upv.logistika}</td>
                        <td
                          className="text-center"
                        >{tim_to_date(tr.upv.do, 3)}</td>
                        <td
                          className="text-center"
                        >
                          {tr.upv.bank}
                        </td>
                        <td
                          className="text-center"
                        >
                          {tr.upv.percent}
                        </td>
                        <td
                          className="text-center"
                        >
                          {tr.upv.term}
                        </td>
                        <td
                          className="text-center"
                        >
                          {tr.upv.dogovor}
                        </td>
                        <td
                          className="text-center"
                        >{priceSet(tr.upv.price)}</td>
                        <td
                          className="text-center"
                        >{priceSet(tr.upv.price_sms)}</td>
                         <td
                          className="text-center"
                        >{priceSet(tr.upv.price_pos)}</td>
                        <td
                          className="text-center"
                        >{priceSet(tr.upv.price - tr.upv.price_sms)}</td>
                        <td
                          className="text-center"
                        >{tr.upv.manager}</td>
                        <td
                          className="text-center"
                        >{tr.upv.di}</td>
                      </tr> : ''
                  )) :
                  <tr key={editId3.id}>
                    {/* <td 
                                        className="text-center"
                                    >{editId3.id}</td>   */}
                    <td
                      className="text-center"
                    >{regions2[editId3.upv.crm]}</td>
                    <td
                      className="text-center"
                    >
                      {editId3.upv.parthner}
                    </td>
                    <td
                      className="text-center"
                    >
                      {editId3.upv.yur}
                    </td>
                    <td
                      className="text-center"
                    >
                      <Form.Control
                        value={editVozvratDate}
                        onChange={e => setEditVozvratDate(e.target.value)}
                        type="date"
                        size="sm"
                      />

                    </td>
                    <td
                      className="text-center"
                    >
                      <Form.Control
                        style={{ fontSize: "1em", width: 20 + 'ch' }}
                        value={editVozvratPrice}
                        size="sm"
                        type="number"
                        onChange={e => setEditVozvratPrice(e.target.value * 1)}
                      />
                    </td>
                    <td
                      className="text-center"
                    >

                      <Form.Control
                        style={{ fontSize: "1em", width: editVozvratDI.length + 10 + 'ch' }}
                        value={editVozvratDI}
                        size="sm"
                        type="text"
                        onChange={e => setEditVozvratDI(e.target.value)}
                      />
                    </td>
                    <td title={(editId3.upv.dcpc == 1) ? tim_to_date(editId3.upv.dcp, 3) : ''}
                      className="text-center"
                    >{(editId3.upv.dcpc == 1) ? '✅' : tim_to_date(editId3.upv.dcp, 3)}</td>
                    <td
                      className="text-center"
                    >
                      <a target="_blank" href={editId3.upv.crm_href}>{editId3.upv.crm_id}</a>
                    </td>
                    <td
                      className="text-center"
                    >
                      {editId3.upv.lid_name}
                    </td>
                    <td
                      className="text-center"
                    >{editId3.upv.logistika}</td>
                    <td
                      className="text-center"
                    >{tim_to_date(editId3.upv.do, 3)}</td>
                    <td
                      className="text-center"
                    >
                      {editId3.upv.bank}
                    </td>
                    <td
                      className="text-center"
                    >
                      {editId3.upv.percent}
                    </td>
                    <td
                      className="text-center"
                    >
                      {editId3.upv.term}
                    </td>
                    <td
                      className="text-center"
                    >
                      {editId3.upv.dogovor}
                    </td>
                    <td
                      className="text-center"
                    >{priceSet(editId3.upv.price)}</td>
                    <td
                      className="text-center"
                    >{priceSet(editId3.upv.price_sms)}</td>
                     <td
                      className="text-center"
                    >{priceSet(editId3.upv.price_pos)}</td>
                    <td
                      className="text-center"
                    >{priceSet(editId3.upv.price - editId3.upv.price_sms)}</td>
                    <td
                      className="text-center"
                    >{editId3.upv.manager}</td>
                    <td
                      className="text-center"
                    >{editId3.upv.di}</td>

                  </tr>

                }

              </tbody> :
              <tbody>
                <tr>
                  <td colSpan={21} className="text-center">
                    <Placeholder animation="glow">
                      <Placeholder xs={2} />
                    </Placeholder>
                  </td>
                </tr>
              </tbody>

            }
          </Table>




        </div> : ''}
      {(editId3 != '' && !loading) ?
        <center>
          <Button size="sm" className="m-1" onClick={() => edit2()} variant="success">Сохранить</Button>
          <Button size="sm" className="m-1" onClick={() => edit2(false, true)} variant="warning">Удалить</Button>
          <Button size="sm" className="m-1" onClick={() => setEditId3('')} variant="danger">Отмена</Button>
        </center>
        : ''}




      {/* <WidthModal show={modal.show} onHide={() => setModal({show:false})} header={modal.header} body={modal.body}/> */}
      <ShowToast show={toast.show} onClose={() => setToast({ show: false })} header={toast.header} body={toast.body} bgg={toast.bgg} />
      <Modal
        show={vozvratM.show}
        onHide={() => setVozvratM({ show: false })}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        style={{ width: '100%' }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span style={{ color: "green" }} key="s1">Возврат для </span>
            <b key="b1">{editLidName2} <span style={{ color: "orange" }}> ({editParthner2})</span>?</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", overflowY: "auto", paddingTop: "0px" }}>
          <Form.Group key="dv" as={Col} xs="12" md="12" className="mt-3" controlId="dv">
            <Form.Label>Дата возврата:</Form.Label>
            <Form.Control

              style={{ fontSize: "1em", width: 20 + 'ch' }}
              value={editVozvratDate}
              onChange={e => setEditVozvratDate(e.target.value)}
              type="date"
              size="sm"
              className="dv"
              name="dv"
            />
          </Form.Group>
          <Form.Group key="pv" as={Col} xs="12" md="12" className="mt-3" controlId="pv">
            <Form.Label>Сумма:</Form.Label>
            <Form.Control
              value={editVozvratPrice}
              onChange={e => setEditVozvratPrice(e.target.value)}
              style={{ fontSize: "1em", width: 20 + 'ch' }}
              size="sm"
              type="number"
              className="pv"
              name="pv"
            />
          </Form.Group>
          <Form.Group key="div" as={Col} xs="12" md="12" className="mt-3" controlId="div">
            <Form.Label>Комментарий:</Form.Label>
            <Form.Control
              value={editVozvratDI}
              onChange={e => setEditVozvratDI(e.target.value)}
              style={{ fontSize: "1em", width: '100%' }}
              size="sm"
              type="text"
              className="div"
              name="div"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" className="m-1" onClick={() => vozvrat()} variant="success">Сохранить</Button>
          <Button size="sm" className="m-1" onClick={() => setVozvratM({ show: false })} variant="danger">Отмена</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={changes.show}
        onHide={() => { setChangess({ show: false }); setChangesData([]); }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        style={{ width: '100%' }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span style={{ color: "green" }} key="s1">Изменения</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: "auto", paddingTop: "0px" }}>
          <div className="row">
            <Form.Group key="ch" as={Col} xs="4" md="4" className="mt-3" controlId="dv">
              <Form.Label>Дата начала изменения:</Form.Label>
              <Form.Control

                style={{ fontSize: "1em", width: 20 + 'ch' }}
                value={changesStart}
                onChange={e => setChangesStart(e.target.value)}
                type="date"
                size="sm"
              />
            </Form.Group>
            <Form.Group key="ch1" as={Col} xs="4" md="4" className="mt-3" controlId="dv">
              <Form.Label>Дата завершения изменения:</Form.Label>
              <Form.Control

                style={{ fontSize: "1em", width: 20 + 'ch' }}
                value={changesEnd}
                onChange={e => setChangesEnd(e.target.value)}
                type="date"
                size="sm"
              />
            </Form.Group>
            <Form.Group key="ch2" as={Col} xs="4" md="4" className="mt-3" controlId="dv">
              <Form.Label>Поисковый запрос:</Form.Label>
              <Form.Control

                style={{ fontSize: "1em", width: 20 + 'ch' }}
                value={changesSearch}
                onChange={e => setChangesSearch(e.target.value)}
                type="text"
                size="sm"
              />
            </Form.Group>

          </div>
          <hr />
          <div style={{ overflow: "auto", maxHeight: "500px" }}>
            <Table style={{ overflow: "auto", fontSize: "0.7em" }} variant={(changesData) ? "light" : "danger"} responsive="md" bordered striped hover className={"sale8table"}>
              <thead>
                <tr>
                  <th className={"text-center"}>
                    Дата изменения
                  </th>
                  <th className={"text-center"}>
                    Тип изменения
                  </th>
                  <th className={"text-center"}>
                    Филиал
                  </th>
                  <th className={"text-center"}>
                    Партнер (бренд/юр.лицо)
                  </th>

                  <th className={"text-center"}>
                    Юр. лицо
                  </th>
                  {/* <th  className={"text-center"}>
                            Дата возврата
                            </th>
                            <th  className={"text-center"}>
                            Сумма возврата
                            </th>
                            <th  className={"text-center"}>
                            Инфо по возврату
                            </th> */}

                  <th className={"text-center"}>
                    Ден.средства поступ.
                  </th>

                  <th className={"text-center"}>
                    ID сделки
                  </th>
                  <th className={"text-center"}>
                    Оформленный договор(ФИО)
                  </th>
                  <th className={"text-center"}>
                    Логистика (накладная)
                  </th>
                  <th className={"text-center"}>
                    Дата одобр.
                  </th>
                  <th className={"text-center"}>
                    Банк
                  </th>
                  <th className={"text-center"}>
                    % ставка
                  </th>
                  <th className={"text-center"}>
                    Cрок
                  </th>
                  <th className={"text-center"}>
                    № кред. договора
                  </th>
                  <th className={"text-center"}>
                    Размер кредита, руб
                  </th>
                  <th className={"text-center"}>
                    СМС сервис(АК), руб
                  </th>
                  <th className={"text-center"}>
                    СМС POS, руб
                  </th>
                  <th className={"text-center"}>
                    Сумма без СМС
                  </th>
                  <th className={"text-center"}>
                    Менеджер АК
                  </th>
                  <th className={"text-center"}>
                    Доп.Информация
                  </th>

                </tr>
              </thead>
              <tbody>
                {changesData.map(tr => (

                  <tr key={tr.id}>
                    <td
                      className="text-center"
                    >{tim_to_date(tr.createdAt, 4)}</td>
                    <td
                      className="text-center"
                    >{tr.changes_type}</td>
                    <td
                      className="text-center"
                    >{regions2[tr.crm]}</td>
                    <td
                      className="text-center"
                    >
                      {tr.parthner}
                    </td>
                    <td
                      className="text-center"
                    >
                      {tr.yur}
                    </td>
                    {/* <td 
                                        className="text-center"
                                    >{tim_to_date(tr.dv,3)}</td>
                                    <td 
                                        className="text-center"
                                    >{priceSet(tr.price)}</td>
                                    <td 
                                        className="text-center"
                                    >
                                        {tr.di}
                                    </td>  */}
                    <td title={(tr.dcpc == 1) ? tim_to_date(tr.dcp, 3) : ''}
                      className="text-center"
                    >{(tr.dcpc == 1) ? '✅' : tim_to_date(tr.dcp, 3)}</td>
                    <td
                      className="text-center"
                    >
                      <a target="_blank" href={tr.crm_href}>{tr.crm_id}</a>
                    </td>
                    <td
                      className="text-center"
                    >
                      {tr.lid_name}
                    </td>
                    <td
                      className="text-center"
                    >{tr.logistika}</td>
                    <td
                      className="text-center"
                    >{tim_to_date(tr.do, 3)}</td>
                    <td
                      className="text-center"
                    >
                      {tr.bank}
                    </td>
                    <td
                      className="text-center"
                    >
                      {tr.percent}
                    </td>
                    <td
                      className="text-center"
                    >
                      {tr.term}
                    </td>
                    <td
                      className="text-center"
                    >
                      {tr.dogovor}
                    </td>
                    <td
                      className="text-center"
                    >{priceSet(tr.price)}</td>
                    <td
                      className="text-center"
                    >{priceSet(tr.price_sms)}</td>
                      <td
                      className="text-center"
                    >{priceSet(tr.price_pos)}</td>
                    <td
                      className="text-center"
                    >{priceSet(tr.price - tr.price_sms)}</td>
                    <td
                      className="text-center"
                    >{tr.manager}</td>
                    <td
                      className="text-center"
                    >{tr.di}</td>
                  </tr>
                ))
                }
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" className="m-1" onClick={() => getChanges()} variant="success">Найти</Button>
          <Button size="sm" className="m-1" onClick={() => { setChangess({ show: false }); setChangesData([]); }} variant="danger">Отмена</Button>
        </Modal.Footer>
      </Modal>
    </Container>


  );
});


export default Up;